import { IconName } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { cloneDeep, compact, findIndex, get, isNil, minBy, pick, set, uniqBy, unset } from 'lodash'
import moment from 'moment'
import omitDeep from 'omit-deep-lodash'
import React, { Component, useEffect, useState } from 'react'
import { Query } from '@apollo/client/react/components'
import { Alert, Button, Col, Row, Table } from 'reactstrap'

import { AssetClassAbbreviationCode, CashFlowCashActivity, CashFlowDefaultKey, CashFlowNetAssetValue, CashFlowPerformance, CashFlows, CashFlowsInput, CashFlowTransaction, CashFlowTransactionInput, CashFlowTransactionType, ClosedEndedVehicle, DeleteCashFlowTransactionInput, UniqueTransactionTypeCode, UniqueTransactionTypeCodeMapItems, VehiclePerformanceCashflowPerformanceQuery, VehiclePerformanceCashflowQuery } from '../../../__generated__/graphql'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { RecursivePartial } from '../../../helpers/typeFunctions'
import numbro from 'numbro'
import { VEHICLE_PERFORMANCE_CASHFLOWS_PERFORMANCE } from '../../../queries/Vehicles'
import { FormInput } from '../../ui/Forms/FormInput'
import PlaceHolder from '../../ui/PlaceHolder'

interface ProductPerformanceVehiclesCashflowProps {
  id: string
  editMode: boolean
  date: string
  data: VehiclePerformanceCashflowQuery
  handleChange: (newDiff:CashFlowsInput) => void
  removeTransaction: (removedTransactionInput:DeleteCashFlowTransactionInput) => void
  editCancelled: boolean
  clearChanges: boolean
}

interface ProductPerformanceVehiclesState {
  stateDiff: CashFlowsInput
  removedInteractions: DeleteCashFlowTransactionInput[]
}

const TransactionTypeOrder = [
  { code: 'C', text: "Called Capital" },
  { code: 'CO', text: "Called Capital Outside Commitment" },
  { code: 'OR', text: "Return Called Capital Outside Commitment"}, // CAL-3171
  { code: 'IP', text: "Income" },
  { code: 'IL', text: "Income - Loss"},
  { code: 'D', text: "Distributed Earnings/ Gains" },
  { code: 'CD', text: "Adjustment of Distributed Capital" },
  { code: 'RC', text: "Return of Capital" },
  { code: 'DR', text: "Distributed Recallable Capital" },
  { code: 'RD', text: "Adjust of Distributed Recallable Capital" },
  { code: 'DU', text: "Return of Uninvested Capital" },
  { code: 'F', text: "Management Fee" },
  { code: 'RF', text: "Return of Management Fee" },
  { code: 'RO', text: "Return Management Fee Outside Commitment" },
  { code: 'FO', text: "Management Fee Outside Commitment" },
  { code: 'FE', text: "Fund Expense" },
  { code: 'RE', text: "Return of Fund Expense" },
  { code: 'CI', text: "Carried Interest" },
  { code: 'CL', text: "Clawback" },
]

class ProductPerformanceVehiclesCashflow extends Component<ProductPerformanceVehiclesCashflowProps,ProductPerformanceVehiclesState> {
  constructor(props: ProductPerformanceVehiclesCashflowProps) {
    super(props)

    this.state = {
      stateDiff: {},
      removedInteractions: []
    }
  }

  static getDerivedStateFromProps(nextProps:ProductPerformanceVehiclesCashflowProps, prevState:ProductPerformanceVehiclesState){
    if(nextProps.editCancelled || nextProps.clearChanges) {
      return { stateDiff: {} };
    }

    return null;
  }

  setStateDiff = (cashFlowInput:CashFlowsInput) => {
    this.setState({stateDiff: cashFlowInput}, () => {
      let newInput = cloneDeep(cashFlowInput)
      newInput.cashFlowTransactionTypes = newInput?.cashFlowTransactionTypes?.map(transactionType => {
        let newTransactionType = cloneDeep(transactionType)

        if (isNil(newTransactionType)) {
          return null
        }

        newTransactionType.transactions = newTransactionType?.transactions?.map(transaction => {
          let newTransaction = cloneDeep(transaction)
          if ((newTransaction?.transactionNumber && newTransaction?.transactionNumber <= 0) || isNil(newTransaction?.transactionNumber)) {
            unset(newTransaction, 'transactionNumber')
          }
          return newTransaction
        })
        return newTransactionType
      }) || []

      this.props.handleChange(newInput)
    })
  }

  handleActivityChange = (property:string, value: number) => {

    let cashFlowInput = cloneDeep(this.state.stateDiff)
    set(cashFlowInput, property, value)
    set(cashFlowInput, "cashFlowCashActivity.periodEndDate", this.props.date)
    // let cashFlowInput = omitDeep(updatedCashflow, '__typename')
    // set(cashFlowInput, 'beginningNetAssetValue.currency', updatedCashflow.beginningNetAssetValue?.currency?.code)
    // set(cashFlowInput, 'beginningNetAssetValue.country', updatedCashflow.beginningNetAssetValue?.country?.code)
    // set(cashFlowInput, 'endingNetAssetValue.currency', updatedCashflow.endingNetAssetValue?.currency?.code)
    // set(cashFlowInput, 'endingNetAssetValue.country', updatedCashflow.endingNetAssetValue?.country?.code)

    // cashFlowInput?.cashFlowTransactionTypes?.map((transactionType:CashFlowTransactionType, ind:number) => {
    //   set(cashFlowInput, `cashFlowTransactionTypes[${ind}].transactionType`, transactionType.transactionType.code)
    //   transactionType?.transactions?.map((transaction:Maybe<CashFlowTransaction>, ind2:number) => {
    //     set(cashFlowInput, `cashFlowTransactionTypes[${ind}].transactions[${ind2}].currency`, transaction?.currency.code)
    //   })
    // })

    this.setStateDiff(cashFlowInput as CashFlowsInput)
  }

  handleEndAssetChange = (endingNetAsset:CashFlowNetAssetValue) => {
    let cashFlowInput = cloneDeep(this.state.stateDiff)

    let endingNetAssetInput = omitDeep(endingNetAsset,'__typename')
    set(endingNetAssetInput, 'currency', endingNetAsset.currency.code)
    set(endingNetAssetInput, 'country', endingNetAsset.country.code)

    cashFlowInput.endingNetAssetValue = endingNetAssetInput
    this.setStateDiff(cashFlowInput as CashFlowsInput)
  }

  handleTransactionChange = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    const vehicle = this.props.data.vehicle_cashflows?.vehicle
    const product = vehicle?.product

    if (vehicle && product) {
      const { cashFlowDefaultKeys } = vehicle
      let cashFlowInput = cloneDeep(this.state.stateDiff)

      let newTransactionTypesInput = cloneDeep(cashFlowInput.cashFlowTransactionTypes)
      if (isNil(newTransactionTypesInput)) {
        newTransactionTypesInput = []
      }

      let updatedTransactionTypeInputIndex = newTransactionTypesInput?.findIndex(type => type?.transactionType === transactionTypeCode)

      let updatedTransactionTypeInput = cloneDeep(get(newTransactionTypesInput, updatedTransactionTypeInputIndex))
      if (updatedTransactionTypeInputIndex < 0 || isNil(updatedTransactionTypeInput)) {
        updatedTransactionTypeInput = {
          transactionType: transactionTypeCode,
          transactions: []
        }
      }

      if (isNil(updatedTransactionTypeInput.transactions)) {
        updatedTransactionTypeInput.transactions = []
      }

      let existingTransactionInputInd = updatedTransactionTypeInput?.transactions?.findIndex(transaction => {
        return (
          transaction?.transactionNumber === updatedTransaction.transactionNumber &&
          transaction?.periodEndDate === updatedTransaction.periodEndDate
        )
      })


      let updatedTransactionInput:CashFlowTransactionInput = {
        periodEndDate: updatedTransaction.periodEndDate,
        transactionDate: updatedTransaction.transactionDate,
        amount: updatedTransaction.amount,
        transactionNumber: updatedTransaction.transactionNumber,
        currency: updatedTransaction.currency.code,
        assetClassAbbreviation: updatedTransaction?.assetClassAbbreviation?.code as AssetClassAbbreviationCode,
        country: updatedTransaction?.country?.code
      }

      if (isNil(existingTransactionInputInd) || existingTransactionInputInd < 0) {
        updatedTransactionTypeInput.transactions.push(updatedTransactionInput)
      } else {
        set(updatedTransactionTypeInput, `transactions[${existingTransactionInputInd}]`, updatedTransactionInput)
      }

      if (updatedTransactionTypeInputIndex < 0) {
        newTransactionTypesInput?.push(updatedTransactionTypeInput)
      } else {
        newTransactionTypesInput[updatedTransactionTypeInputIndex] = updatedTransactionTypeInput
      }

      cashFlowInput.cashFlowTransactionTypes = newTransactionTypesInput
      this.setStateDiff(cashFlowInput as CashFlowsInput)
    }
  }

  handleTransactionRemove = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    this.props.removeTransaction(pick(updatedTransaction, ['periodEndDate','transactionDate', 'transactionNumber']))

    let cashFlowInput = cloneDeep(this.state.stateDiff)
    let newTransactionTypesInput = cloneDeep(cashFlowInput.cashFlowTransactionTypes)
    if (isNil(newTransactionTypesInput)) {
      return
    }

    let updatedTransactionTypeInputIndex = newTransactionTypesInput?.findIndex(type => type?.transactionType === transactionTypeCode)

    let updatedTransactionTypeInput = cloneDeep(get(newTransactionTypesInput, updatedTransactionTypeInputIndex))

    if (updatedTransactionTypeInputIndex < 0 || isNil(updatedTransactionTypeInput) || isNil(updatedTransactionTypeInput.transactions)) {
      return
    }

    let newTransactionInputs = updatedTransactionTypeInput.transactions
    let removedTransactionInputInd = -1
    if (updatedTransaction.transactionNumber !== 0) {
      removedTransactionInputInd = newTransactionInputs?.findIndex(transaction => {
        return (
          transaction?.transactionNumber === updatedTransaction.transactionNumber &&
          transaction?.transactionDate === updatedTransaction.transactionDate
        )
      })
    }
    if (isNil(removedTransactionInputInd) || removedTransactionInputInd < 0) {
      return
    }
    newTransactionInputs.splice(removedTransactionInputInd,1)
    updatedTransactionTypeInput.transactions = newTransactionInputs
    newTransactionTypesInput[updatedTransactionTypeInputIndex] = updatedTransactionTypeInput

    cashFlowInput.cashFlowTransactionTypes = newTransactionTypesInput
    this.setStateDiff(cashFlowInput as CashFlowsInput)
  }


  render() {
    const { editMode, date, data, editCancelled } = this.props

    const vehicle = data.vehicle_cashflows?.vehicle
    const product = vehicle?.product

    let transactionTypeCodes: UniqueTransactionTypeCodeMapItems[] = compact(data.assetClassMaps?.uniqueTransactionTypeCodeAssetClassMap?.filter(assetMap => assetMap?.assetMixNum === product?.product?.assetClass?.parent?.id)) || []
    transactionTypeCodes = uniqBy(transactionTypeCodes, 'code')

    transactionTypeCodes = transactionTypeCodes.sort((a,b) => findIndex(TransactionTypeOrder, c => c.code === a.code) - findIndex(TransactionTypeOrder, c => c.code === b.code))
    // filter out transaction types that are not in the order list
    transactionTypeCodes = transactionTypeCodes.filter(typeCode => findIndex(TransactionTypeOrder, c => c.code === typeCode.code) >= 0)
    const cashflowDefaultKeys = vehicle?.cashFlowDefaultKeys
    // const cashflowDefaultKeys:CashFlowDefaultKey = {
    //   __typename: 'CashFlowDefaultKey',
    //   currency: PortfolioCurrencyCode.USADOL,
    //   country: CountryCode.USA,
    //   assetClassAbbreviation: AssetClassAbbreviationCode.RE
    // }
    if (data.vehicle_cashflows && vehicle && product && "closedEnded" in product && cashflowDefaultKeys) {
      return (
        <CashflowDisplay
          key={`cashflow-${vehicle.id}-display-${date}-${editCancelled}`}
          editMode={editMode}
          cashflows={vehicle.cashFlows as CashFlows || null}
          cashflowDefaults={cashflowDefaultKeys}
          transactionTypeMap={transactionTypeCodes}
          finalCommitSize={product?.closedEnded?.finalCommitSize || 0}
          vehicleType={data.vehicle_cashflows.__typename}
          handleActivityChange={this.handleActivityChange}
          handleTransactionChange={this.handleTransactionChange}
          handleTransactionRemove={this.handleTransactionRemove}
          handleEndAssetChange={this.handleEndAssetChange}
          date={date}
          vehicleId={vehicle.id}
          editCancelled={editCancelled}
        />
      )
    }
    return <></>
  }
}

interface CashflowDisplayProps {
  editMode: boolean
  cashflows: CashFlows | null
  cashflowDefaults: CashFlowDefaultKey
  vehicleType: string
  finalCommitSize: number
  handleActivityChange: (property:string, value:number) => void
  handleTransactionChange: (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => void
  handleTransactionRemove: (transactionTypeCode: UniqueTransactionTypeCode, removedTransaction:CashFlowTransaction) => void
  handleEndAssetChange: (endingNetAsset:CashFlowNetAssetValue) => void
  date: string
  vehicleId: string
  editCancelled: boolean
  transactionTypeMap: UniqueTransactionTypeCodeMapItems[]
  serviceStartDate?: string
}

interface CashflowDisplayState {
  currentState: CashFlows
  initialState: CashFlows
}

class CashflowDisplay extends Component<CashflowDisplayProps,CashflowDisplayState> {
  constructor(props: CashflowDisplayProps) {
    super(props)

    let cashflows:CashFlows = {
      beginningNetAssetValue: props.cashflows?.beginningNetAssetValue || null,
      endingNetAssetValue: props.cashflows?.endingNetAssetValue || null,
      cashFlowTransactionTypes: props.cashflows?.cashFlowTransactionTypes || [],
      cashFlowCashActivity: props.cashflows?.cashFlowCashActivity || null,
      __typename: "CashFlows"
    }

    this.state = {
      currentState: cashflows,
      initialState: cashflows
    }
  }

  static getDerivedStateFromProps(nextProps:CashflowDisplayProps, prevState:CashflowDisplayState){
    if(nextProps.editCancelled || !nextProps.editMode) {
      return { currentState: nextProps.cashflows, initialState: nextProps.cashflows };
    }

    return null;
  }

  handleTransactionUpdate = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    let newState = cloneDeep(this.state.currentState)

    let newTransactionTypes = cloneDeep(newState.cashFlowTransactionTypes)
    if (isNil(newTransactionTypes)) {
      newTransactionTypes = []
    }

    let updatedTransactionTypeIndex = newTransactionTypes?.findIndex(type => type?.transactionType?.code === transactionTypeCode)
    let updatedTransactionType = cloneDeep(get(newTransactionTypes, updatedTransactionTypeIndex))
    if (updatedTransactionTypeIndex < 0 || isNil(updatedTransactionType)) {
      updatedTransactionType = {
        __typename: 'CashFlowTransactionType',
        transactionType: { __typename: 'TransactionTypeLookup', code: transactionTypeCode },
        transactions: []
      }
    }

    if (isNil(updatedTransactionType.transactions)) {
      updatedTransactionType.transactions = []
    }

    let existingTransactionInd = updatedTransactionType?.transactions?.findIndex(transaction => transaction?.transactionNumber === updatedTransaction.transactionNumber)

    if (isNil(existingTransactionInd) || existingTransactionInd < 0) {
      updatedTransactionType.transactions.push(updatedTransaction)
    } else {
      set(updatedTransactionType, `transactions[${existingTransactionInd}]`, updatedTransaction)
    }

    if (updatedTransactionTypeIndex < 0) {
      newTransactionTypes?.push(updatedTransactionType)
    } else {
      newTransactionTypes[updatedTransactionTypeIndex] = updatedTransactionType
    }

    newState.cashFlowTransactionTypes = newTransactionTypes
    this.setState({ currentState: newState },() => {
      this.props.handleTransactionChange(transactionTypeCode, updatedTransaction)
    })
  }

  handleTransactionRemove = (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => {
    let newState = cloneDeep(this.state.currentState)
    let newTransactionTypes = cloneDeep(newState.cashFlowTransactionTypes)
    if (isNil(newTransactionTypes)) {
      return
    }

    let updatedTransactionTypeIndex = newTransactionTypes?.findIndex(type => type?.transactionType?.code === transactionTypeCode)

    let updatedTransactionType = cloneDeep(get(newTransactionTypes, updatedTransactionTypeIndex))
    if (updatedTransactionTypeIndex < 0 || isNil(updatedTransactionType)) {
      return
    }

    if (isNil(updatedTransactionType.transactions)) {
      return
    }

    let newTransactions = updatedTransactionType.transactions
    let removedTransactionInd = newTransactions.findIndex(transaction => transaction?.transactionNumber === updatedTransaction.transactionNumber)

    if (isNil(removedTransactionInd) || removedTransactionInd < 0) {
      return
    }

    newTransactions.splice(removedTransactionInd,1)
    updatedTransactionType.transactions = newTransactions
    newTransactionTypes[updatedTransactionTypeIndex] = updatedTransactionType

    newState.cashFlowTransactionTypes = newTransactionTypes
    this.setState({ currentState: newState },() => {
      this.props.handleTransactionRemove(transactionTypeCode, updatedTransaction)
    })
  }

  handleActivityUpdate = (property:keyof CashFlowCashActivity, value:number) => {
    let newState = cloneDeep(this.state.currentState)
    let newActivity = cloneDeep(newState.cashFlowCashActivity)

    if (!newActivity) {
      newActivity = { __typename: 'CashFlowCashActivity', periodEndDate: this.props.date}
    }
    set(newActivity, property, value)

    newState.cashFlowCashActivity = newActivity
    this.setState({ currentState: newState },() => {
      this.props.handleActivityChange(`cashFlowCashActivity.${property}`, value)
    })
  }

  handleEndAssetChange = (value: number ) => {
    let newState = cloneDeep(this.state.currentState)
    let newEndingValue = cloneDeep(newState.endingNetAssetValue)

    if (newEndingValue) {
      set(newEndingValue, 'amount', value)
    } else {
      newEndingValue = {
        __typename: 'CashFlowNetAssetValue',
        accruedInterests: 0,
        assetClassAbbreviation: this.props.cashflowDefaults.assetClassAbbreviation,
        country: { code: this.props.cashflowDefaults.country, __typename: 'PortfolioCountryLookup' },
        date: this.props.date,
        amount: value,
        currency: { code: this.props.cashflowDefaults.currency, __typename: 'PortfolioCurrencyLookup' }
      }
    }

    newState.endingNetAssetValue = newEndingValue
    this.setState({ currentState: newState },() => {
      if (newEndingValue) {
        this.props.handleEndAssetChange(newEndingValue)
      }
    })
  }

  generatePerformanceParams = () => {
    let state = this.state.initialState

    let input = {
      beginningNetAssetValue: state.beginningNetAssetValue ? {
        date: state.beginningNetAssetValue?.date,
        amount: state.beginningNetAssetValue?.amount,
        accruedInterests: state.beginningNetAssetValue?.accruedInterests,
        currency: state.beginningNetAssetValue?.currency?.code
      } : null,
      endingNetAssetValue: state.endingNetAssetValue ? {
        date: state.endingNetAssetValue?.date,
        amount: state.endingNetAssetValue?.amount,
        accruedInterests: state.endingNetAssetValue?.accruedInterests,
        currency: state.endingNetAssetValue?.currency?.code
      } : null,
      numeraire: this.props.cashflowDefaults.currency,
      cashFlowTransactionTypes: state.cashFlowTransactionTypes?.map(ttype => {
        return {
          transactionType: ttype?.transactionType?.code,
          transactions: ttype?.transactions?.map(transaction => { return {
            date: transaction?.transactionDate,
            amount: transaction?.amount,
            transactionNumber: transaction?.transactionNumber,
            currency: transaction?.currency?.code,
          }})
        }
      }),
      net: true,
      committedCapital: this.props.finalCommitSize
    }

    return input
  }

  calculateAppreciation = () => {
    const endingNetAssetValue = this.state.currentState?.endingNetAssetValue?.amount || 0
    const beginningNetAssetValue = this.state.currentState?.beginningNetAssetValue?.amount || 0

    let total = endingNetAssetValue - beginningNetAssetValue

    // endingNetAssetValue - beginningNetAssetValue - sum(C) -sum(CI) + sum(CL) - sum(CO) + sum(RC) + sum(D) + sum(DR) + sum (DU) + sum(F)- sum(RF) + sum(FE) - sum(RE) + sum(FO) - sum(RO) - sum(IP) + sum(IL)

    // updated on 2024/04 https://callanllc.atlassian.net/browse/CAL-3171?focusedCommentId=85551
    // endingNetAssetValue - beginningNetAssetValue -sum(C)-sum(CO)+sum(OR)-sum(IP)+sum(IL)+sum(D)-sum(CD)+sum(RC)+sum(DR)-sum(RD)+sum(DU)+sum(F)-sum(RF)+sum(FO)-sum(RO)+sum(FE)-sum(RE)+sum(CI)-sum(CL)
    this.state.currentState.cashFlowTransactionTypes?.map(transactionType => {
      if (!transactionType) {
        return 0
      }

      const currentNetValue = this.transactionsInCurrentPeriodNetValue(transactionType)
      const type = transactionType?.transactionType
      // if (type?.code && ["CL","RC","D","DR","DU", "F","FE","FO","IL"].includes(type?.code)) {
      if (type?.code && ["OR","CI","RC","D","DR","DU", "F","FE","FO","IL"].includes(type?.code)) {
        return total += currentNetValue || 0
      } else {
        return total -= currentNetValue || 0
      }
    })

    return total
  }

  transactionsInCurrentPeriod = (transactionType:CashFlowTransactionType) => {
    return compact(transactionType?.transactions?.filter(t => t?.periodEndDate === this.props.date )) || []
  }

  transactionsInCurrentPeriodNetValue = (transactionType:CashFlowTransactionType):number => {
    const reducer = (sum:number, t:CashFlowTransaction) => { return sum + t.amount }
    return this.transactionsInCurrentPeriod(transactionType).reduce(reducer, 0)
  }

  render() {
    const cashflows = this.state.currentState
    const { editMode } = this.props
    const generatedParams = this.generatePerformanceParams()

    return(
      <>
      <div className="pane pane-table">
        <Row>
          <Col md="8">
            <Row>
              <Col md="12">
                <h3 className="subheader underline green-underline">
                  Transactions
                </h3>
                Please enter all transactions as positive numbers.  All transactions will be saved as positive values.
              </Col>
            </Row>
            <Table borderless size="sm">
              <thead>
                <tr>
                  <td className="w-25 width-160"></td>
                  <td className="text-uppercase text-gray-50 text-left w-25"><small>Date</small></td>
                  <td className="text-uppercase text-gray-50 text-right w-50"><small>Net Value</small></td>
                </tr>
              </thead>
              <tbody>
                <tr className='text-callan-blue font-weight-bold row-highlight'>
                  <td className="text-left">
                    Beginning net asset value
                  </td>
                  <td className="text-left">{  cashflows.beginningNetAssetValue?.date || this.props.date }</td>
                  <td className="text-right"><span className="strong-emphasis">{ numbro(cashflows.beginningNetAssetValue?.amount  || 0).format('$0,0.00')}</span></td>
                </tr>
              </tbody>
            </Table>
            { this.props.transactionTypeMap.map((transactionTypeCode, idx: number) => {
              return (
                <TransactionTypeTable
                  key={`transaction-table-${idx}`}
                  transactionTypeCode={transactionTypeCode.code}
                  transactionType={cashflows?.cashFlowTransactionTypes?.find(t => t?.transactionType.code === transactionTypeCode.code)}
                  editMode={editMode}
                  transactionTypeNum={idx}
                  updateTransactionType={this.handleTransactionUpdate}
                  removeTransaction={this.handleTransactionRemove}
                  date={this.props.date}
                  cashflowDefaults={this.props.cashflowDefaults}
                />
              )
            })}
            {/* {
              cashflows?.cashFlowTransactionTypes?.sort((a,b) => (a?.transactionType?.code || 0) < (b?.transactionType?.code || 0) ? -1 : 1).map((transactionType, idx:number) =>
                <TransactionTypeTable
                  key={`transaction-table-${idx}`}
                  transactionTypeCode={transactionTypeCode}
                  transactionType={transactionType}
                  editMode={editMode}
                  transactionTypeNum={idx}
                  updateTransactionType={this.handleTransactionUpdate}
                  removeTransaction={this.handleTransactionRemove}
                  date={this.props.date}
                  cashflowDefaults={this.props.cashflowDefaults}
                />
              )
            } */}
            <Table borderless size="sm" className="mt-3">
              <thead>
                <tr>
                  <th className="text-left width-160 w-25"><strong>Ending net asset value</strong></th>
                  <td className="text-uppercase text-gray-50 text-left w-25"><small>Date</small></td>
                  <td className="text-uppercase text-gray-50 text-right w-50"><small>Net Value</small></td>
                </tr>
              </thead>
              <tbody>
                <tr className="background-gray-30">
                  <td></td>
                  <td className="text-left">
                    {moment(cashflows.endingNetAssetValue?.date || this.props.date ).format(DATE_DISPLAY_FORMAT)}
                  </td>
                  <td className="text-right">
                    <FormInput
                      idx={`endingNetAssetValue-amount`}
                      property={`endingNetAssetValue-amount`}
                      displayName=''
                      type='float'
                      subtype='currency'
                      editMode={editMode}
                      propertyVal={cashflows.endingNetAssetValue?.amount}
                      updateValue={(v) => this.handleEndAssetChange(v)}
                    />
                  </td>

                </tr>
              </tbody>
            </Table>
            <Table borderless size="sm" className="mt-3">
              <thead>
                <tr>
                  <th></th>
                  <td className="text-uppercase text-gray-50 text-right"><small>Amount</small></td>
                </tr>
              </thead>
              <tbody>
                <tr className='text-callan-blue font-weight-bold row-highlight'>
                  <td className="text-left">
                    <strong>Appreciation/Depreciation *</strong>
                  </td>
                  <td className="text-right"><span className="strong-emphasis">{ numbro(this.calculateAppreciation() || 0).format('$0,0.00')}</span></td>
                </tr>
              </tbody>
            </Table>
            <small className="text-gray-50">* Calculated using the transaction data shown on this screen.</small>

          </Col>
          <Col md="4">
            <Query<VehiclePerformanceCashflowPerformanceQuery> query={VEHICLE_PERFORMANCE_CASHFLOWS_PERFORMANCE} variables={{ id: this.props.vehicleId, date: this.props.date, performanceInput: generatedParams }} fetchPolicy="cache-and-network" notifyOnNetworkStatusChange={true}>
              {
                ({loading, error, data}) => {
                  if (loading) {
                    return <PlaceHolder />
                  }

                  if (error) {
                    return (
                      <Alert color="warning">
                        Unable to retrieve cash flows performance metrics.
                      </Alert>
                    )
                  }

                  return (
                    <>
                      <PerformanceTable
                        editMode={editMode}
                        cashflowPerformance={data?.vehicle_cashflow_performance?.vehicle?.cashFlowsPerformance}
                        cashflowType='vehicle'
                        serviceStartDate={(data?.vehicle_cashflow_performance as ClosedEndedVehicle)?.closedEndedVehicle?.vintageFirstCashFlow}
                        vehicleType={data?.vehicle_cashflow_performance?.__typename || "" as string}
                      />

                      <ValuationsTable
                        editMode={editMode}
                        cashFlowCashActivity={cashflows.cashFlowCashActivity}
                        updateActivity={this.handleActivityUpdate}
                        vehicleType={this.props.vehicleType}
                      />

                      <CashActivityTable
                        editMode={editMode}
                        cashFlowCashActivity={cashflows.cashFlowCashActivity}
                        cashflowPerformance={data?.vehicle_cashflow_performance?.vehicle?.cashFlowsPerformance}
                        updateActivity={this.handleActivityUpdate}
                        vehicleType={this.props.vehicleType}
                        />
                    </>
                  )
                }
              }
            </Query>
          </Col>
        </Row>
      </div>
    </>
    )
  }
}

interface TransactionTypeTableProps {
  transactionTypeCode: UniqueTransactionTypeCode
  transactionType?: CashFlowTransactionType | null
  editMode: boolean
  transactionTypeNum: number
  date: string
  serviceStartDate?: string
  updateTransactionType: (transactionTypeCode: UniqueTransactionTypeCode, updatedTransaction:CashFlowTransaction) => void
  removeTransaction: (transactionTypeCode: UniqueTransactionTypeCode, removedTransaction:CashFlowTransaction) => void
  cashflowDefaults: CashFlowDefaultKey
}

const toolTipConfigs = [
  {
    code: 'CI',
    settings: {
      icon: "question-circle",
      id: "carriedInterestTooltip",
    }
  }
]

export const TransactionTypeTable = (props:TransactionTypeTableProps) => {
  const {transactionType:propsTransactionType, editMode, transactionTypeNum, updateTransactionType, transactionTypeCode} = props
  const [transactionType, setTransactionType] = useState(propsTransactionType)

  useEffect(() => {
    if (!editMode) {
      setTransactionType(propsTransactionType)
    }
  }, [propsTransactionType, editMode])

  const transactionTypeHeader = (code?:UniqueTransactionTypeCode | null) => {
    if (isNil(code)) {
      return ""
    }

    const tt = TransactionTypeOrder.find(c => c.code === code)
    return tt ? tt.text : ""
  }

  const onChange = (rowId:number, property: keyof CashFlowTransaction, value: string) => {
    let newTransactionType = cloneDeep(fetchTransactionType())
    let newTransaction: CashFlowTransaction = cloneDeep(get(newTransactionType,`transactions[${rowId}]`)) as any as CashFlowTransaction

    set(newTransaction, property, value)

    let date = moment(value)
    let endOfQuarter = moment(props.date).endOf('quarter').format(DATE_API_FORMAT)
    let startOfQuarter = moment(props.date).startOf('quarter').format(DATE_API_FORMAT)

    if (property === 'transactionDate') {
      if ((moment(date).isSameOrAfter(props.serviceStartDate) || moment(date).isSameOrAfter(startOfQuarter)) && moment(date).isSameOrBefore(endOfQuarter)) {
        set(newTransaction, 'periodEndDate', endOfQuarter)
      }

      if(newTransaction.transactionNumber >= 0){
        // Changed date for already saved row so must delete and create new in backend
        const minTransactionNumber = minBy(newTransactionType?.transactions, "transactionNumber")?.transactionNumber || 0
        set(newTransaction, "transactionNumber", (minTransactionNumber >= 0) ? -1 : (minTransactionNumber -1))
        props.removeTransaction(newTransactionType.transactionType.code, cloneDeep(get(newTransactionType,`transactions[${rowId}]`, {} as CashFlowTransaction)))
      }
    }

    set(newTransactionType, `transactions[${rowId}]`, newTransaction)

    setTransactionType({...newTransactionType})
    if (transactionType?.transactionType?.code) {
      if ((moment(date).isSameOrAfter(props.serviceStartDate) || moment(newTransaction.transactionDate).isSameOrAfter(startOfQuarter)) && moment(newTransaction.transactionDate).isSameOrBefore(endOfQuarter)) {
        updateTransactionType(transactionType.transactionType.code, newTransaction)
      }
    }
  }

  const fetchTransactionType = ():CashFlowTransactionType => {
    if (!isNil(transactionType)) {
      return transactionType
    }

    return {
      __typename: 'CashFlowTransactionType',
      transactionType: { code: transactionTypeCode, __typename: 'TransactionTypeLookup'},
      transactions: [],
      netValue: 0
    }
  }
  const addRow = () => {
    let newTransactionType = cloneDeep(fetchTransactionType())

    const dateNow = moment(props.date)

    const sortedTransactions = newTransactionType?.transactions
    ?.filter(transaction => transaction?.transactionNumber && transaction?.transactionNumber < 0)
    .sort((a,b) => (a?.transactionNumber || 0) - (b?.transactionNumber || 0))[0]
    const newTransactionNumber = (sortedTransactions?.transactionNumber != null ? sortedTransactions?.transactionNumber : -1) - 1

    let newTransaction:CashFlowTransaction = {
      __typename: 'CashFlowTransaction',
      periodEndDate: dateNow.endOf('quarter').format(DATE_API_FORMAT),
      transactionDate: dateNow.format(DATE_API_FORMAT),
      amount: 0,
      currency: { code: props.cashflowDefaults.currency, __typename: 'PortfolioCurrencyLookup' },
      transactionNumber: newTransactionNumber,
      assetClassAbbreviation: { code: props.cashflowDefaults.assetClassAbbreviation as AssetClassAbbreviationCode, __typename: 'AssetClassAbbreviationLookup' },
      country: { code: props.cashflowDefaults.country, __typename: 'PortfolioCountryLookup' },
      adjustedTransactionDate: dateNow.format(DATE_API_FORMAT),
    }

    if (isNil(newTransactionType.transactions)){
      newTransactionType.transactions = [newTransaction]
    } else {
      newTransactionType.transactions.push(newTransaction)
    }

    setTransactionType({...newTransactionType})

  }

  const deleteRow = (rowId:number) => {
    let newTransactionType = cloneDeep(fetchTransactionType())
    let transactions = cloneDeep(newTransactionType.transactions) || []

    let removedTransaction = transactions[rowId]
    if (removedTransaction) {
      transactions?.splice(rowId, 1)
      newTransactionType.transactions = transactions

      setTransactionType({...newTransactionType})
      props.removeTransaction(newTransactionType.transactionType.code, removedTransaction)
    }
  }

  const transactionsInPeriod = () => {
    return compact(transactionType?.transactions?.filter(t => t?.periodEndDate === props.date )) || []
  }

  const netValue = () => {
    const reducer = (sum:number, t:CashFlowTransaction) => { return sum + t.amount }
    return transactionsInPeriod().reduce(reducer, 0)
  }

  const hasTooltip = (code:UniqueTransactionTypeCode) => {
    return toolTipConfigs.find(c => c.code === code)
  }

  const toolTipId = toolTipConfigs.find(c => c.code === transactionTypeCode)?.settings.id
  return (
    <Table className="mt-3 cashflow-transaction-table">
      <thead>
        <tr>
          { hasTooltip(transactionTypeCode) ? (<td className="text-capitalize border-top-0 width-160 text-left w-25" id={toolTipId+"Container"}>
            <div className="d-inline-flex align-items-center tooltip-icon" id={toolTipId}>
              <strong>{transactionTypeHeader(transactionTypeCode)}</strong>
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                size="sm"
              />
            </div>
          </td>):
          <td className="text-capitalize border-top-0 width-160 text-left w-25"><strong>{transactionTypeHeader(transactionTypeCode)}</strong></td>}
          <td className="text-uppercase text-gray-50 text-left border-top-0 w-25"><small>Date</small></td>
          <td className="text-uppercase text-gray-50 text-right border-top-0 w-25"><small>Amount</small></td>
          <td className="border-top-0 w-25"></td>
        </tr>
      </thead>
      <tbody className="bordered">
        { transactionType?.transactions?.map((transaction, idx:number) => {
          if (isNil(transaction) || transaction.periodEndDate !== props.date) {
            return <React.Fragment key={`transaction-row-${transactionTypeNum}-blank-${idx}`}></React.Fragment>
          }
          return (
            <tr key={`transaction-row-${transactionTypeNum}-${transaction.transactionDate}-${transaction.transactionNumber}`}>
              <td></td>
              <td className="text-left">
                <FormInput
                  idx={`transaction-${transactionTypeNum}-${idx}-transactionDate`}
                  property={`transaction-${transactionTypeNum}-${idx}-transactionDate`}
                  displayName=''
                  type='date'
                  editMode={editMode}
                  // readonly={transaction.transactionNumber >= 0}
                  propertyVal={transaction.transactionDate}
                  updateValue={(v) => onChange(idx, 'transactionDate', v)}
                  required={true}
                  inputProps={{
                    min: props.serviceStartDate || moment(props.date).startOf('quarter').format(DATE_API_FORMAT),
                    max: props.date
                  }}
                  subClasses={{
                    inputClasses: 'text-left'
                  }}
                />
              </td>
              <td className="text-right">
                <FormInput
                  idx={`transaction-${transactionTypeNum}-${idx}-amount`}
                  property={`transaction-${transactionTypeNum}-${idx}-amount`}
                  displayName=''
                  type='float'
                  subtype='currency'
                  editMode={editMode}
                  propertyVal={transaction.amount}
                  nonNegative={true}
                  updateValue={(v) => onChange(idx, 'amount', v)}
                />
              </td>
              <td>
                { editMode && (
                <button className="btn-no-style delete-transaction-btn text-right" onClick={() => deleteRow(idx)}>
                  <FontAwesomeIcon icon="trash" className="text-accent-red" />
                </button>)}
              </td>
            </tr>
          )

        })}
        { editMode && (
          <tr>
            <td>
              <Button onClick={addRow} className="btn-no-style">
                Add Row
                &nbsp;
                <FontAwesomeIcon icon="plus-circle" />
              </Button>
            </td>
            <td colSpan={3}></td>
          </tr>
        )}
        { !editMode && (
          <tr>
            <td colSpan={3}></td>
            <td className='text-right'>
              <small className="text-uppercase text-gray-50">Net Value</small><br/>
              <strong className="text-callan-blue">{ numbro(netValue() || 0).format('$0,0.00')}</strong>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

interface PerformanceTableProps {
  editMode: boolean
  cashflowPerformance?: CashFlowPerformance | null
  cashflowType: 'client' | 'vehicle'
  serviceStartDate?: string
  vehicleType: string
}
export const PerformanceTable = ({editMode, cashflowPerformance,cashflowType, serviceStartDate, vehicleType }:PerformanceTableProps) => {
  let inceptionDate: string | undefined = ""
  // https://callanllc.atlassian.net/browse/CAL-2592?focusedCommentId=72331
  if (cashflowType === "client") {
    inceptionDate = serviceStartDate
  }else if(cashflowType === "vehicle") {
    inceptionDate = serviceStartDate
  }

  const closedEndedTypes = [ "ClosedEndedPrivateNonRegisteredMulitAssetClass", "ClosedEndedPrivateNonRegisteredPrivateCredit", "ClosedEndedPrivateNonRegisteredPrivateEquity", "ClosedEndedPrivateNonRegisteredRealAssets"]
  let isClosedEnded: boolean = closedEndedTypes.includes(vehicleType)
  const inceptionDateLabel = isClosedEnded ? "Vintage- First Cash Flow" : "Inception Date"

  if (editMode) {
    return (
      <>
        <h4>Since Inception Performance</h4>
        <Table size='sm' className='table-condensed mb-0'>
          <tbody>
            <tr className='row-highlight'>
              <td className='text-left text-callan-blue'>
                {inceptionDateLabel}
                {cashflowType === 'client' && (
                  <div className='d-inline-flex align-items-center tooltip-icon' id='inceptionDateTooltip'>
                    <FontAwesomeIcon icon={'question-circle' as IconName} size='sm' />
                  </div>
                )}
              </td>
              <td className='text-right background-blue-10'>
                {moment(inceptionDate).format(DATE_DISPLAY_FORMAT)}
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    );
  }

  return (
    <>
      <h4>Since Inception Performance</h4>
      <Table size="sm" className="table-condensed mb-0">
        <tbody>
          <tr className="row-highlight">
            <td className="text-left text-callan-blue">Inception Date
              {cashflowType === 'client' &&
                <div className="d-inline-flex align-items-center tooltip-icon" id="inceptionDateTooltip">
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              }
            </td>
            <td className="text-right background-blue-10">{moment(inceptionDate).format(DATE_DISPLAY_FORMAT)}</td>
          </tr>
          <tr className="row-highlight">
            <td className="text-left text-callan-blue">TVPI *</td>
            <td className="text-right background-blue-10">{numbro(cashflowPerformance?.tvpi || 0).format('0.00')}x</td>
          </tr>
          <tr className="row-highlight">
            <td className="text-left text-callan-blue">RVPI *</td>
            <td className="text-right background-blue-10">{numbro(cashflowPerformance?.rvpi || 0).format('0.00')}x</td>
          </tr>
          <tr className="row-highlight">
            <td className="text-left text-callan-blue">DPI *</td>
            <td className="text-right background-blue-10">{numbro(cashflowPerformance?.dpi || 0).format('0.00')}</td>
          </tr>
          <tr className="row-highlight">
            <td className="text-left text-callan-blue">Net IRR *</td>
            <td className="text-right background-blue-10">{numbro(cashflowPerformance?.irr || 0).format('0.00%')}</td>
          </tr>
        </tbody>
      </Table>
      <small className="text-gray-50">{cashflowPerformance?.startDate && `* Calculated using transaction data as of ${moment(cashflowPerformance?.startDate).format(DATE_DISPLAY_FORMAT)}`}</small>
    </>
  )
}

interface ValuationsTableProps {
  cashFlowCashActivity?: RecursivePartial<CashFlowCashActivity> | null
  editMode: boolean
  updateActivity: (property:keyof CashFlowCashActivity, value:number) => void
  vehicleType: string
}
export const ValuationsTable = (props:ValuationsTableProps) => {
  const { editMode } = props
  const [cashFlowCashActivity, setCashFlowCashActivity] = useState(props.cashFlowCashActivity)

  const onChange = (property:keyof CashFlowCashActivity, value:number) => {
    let newActivity = cloneDeep(cashFlowCashActivity) || {}
    set(newActivity, property, value)
    setCashFlowCashActivity(newActivity)
    props.updateActivity(property, value)
  }

  const OpenTypes = ["OpenEndedPooledVehicleRealAssets",  "OpenEndedSeparateAccountRealAssets"]

  const isPrivate = ["PrivateEquity", "PrivateCredit"].find(el => props.vehicleType?.includes(el))
  if(isPrivate){
    return <></>
  }

  return (
    <>
    <h4 className="mt-4">Valuation</h4>
    <Table size="sm" className="table-condensed">
      <tbody>
        <tr>
          <td className="text-left">Gross Asset Value ($)</td>
          <td>
            <FormInput
              idx="valuation-grossAssetValue"
              property="valuation-grossAssetValue"
              displayName=''
              type='float'
              subtype='currency'
              editMode={editMode}
              propertyVal={cashFlowCashActivity?.grossAssetValue}
              updateValue={(v) => onChange('grossAssetValue', v)}
            />
          </td>
        </tr>
        { OpenTypes.includes(props.vehicleType) && (
          <tr>
            <td className="text-left">Net Asset Value ($)</td>
            <td>
              <FormInput
                idx="valuation-netAssetValue"
                property="valuation-netAssetValue"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.netAssetValue}
                updateValue={(v) => onChange('netAssetValue', v)}
              />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
    </>
  )
}

interface CashActivityTableProps {
  cashFlowCashActivity?: RecursivePartial<CashFlowCashActivity> | null
  editMode: boolean
  updateActivity: (property:keyof CashFlowCashActivity, value:number) => void
  cashflowPerformance?: CashFlowPerformance | null
  vehicleType: string
}
export const CashActivityTable = (props:CashActivityTableProps) => {
  const { editMode, cashflowPerformance } = props
  const [cashFlowCashActivity, setCashFlowCashActivity] = useState(props.cashFlowCashActivity)

  const onChange = (property:keyof CashFlowCashActivity, value:number) => {
    let newActivity = cloneDeep(cashFlowCashActivity) || {}
    set(newActivity, property, value)
    setCashFlowCashActivity(newActivity)
    props.updateActivity(property, value)
  }

  const OpenTypes = ["OpenEndedPooledVehicleRealAssets", "OpenEndedPooledVehiclePrivateEquity", "OpenEndedPooledVehiclePrivateCredit", "OpenEndedSeparateAccountRealAssets"]

  const isPrivate = ["PrivateEquity", "PrivateCredit"].find(el => props.vehicleType?.includes(el))
  return (
    <>
      <h4 className="mt-5">Cash Activity</h4>
      <Table size="sm" className="table-condensed mb-0">
        <tbody>
          {!isPrivate && <tr>
            <td className="text-left">Cash Value ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-cashValue"
                property="valuation-cashValue"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.cashValue}
                updateValue={(v) => onChange('cashValue', v)}
              />
            </td>
          </tr>}
          { OpenTypes.includes(props.vehicleType) && (
            <>
              <tr>
                <td className="text-left">Entry Queue ($)</td>
                <td className="text-right">
                  <FormInput
                    idx="valuation-entryQueue"
                    property="valuation-entryQueue"
                    displayName=''
                    type='float'
                    subtype='currency'
                    editMode={editMode}
                    propertyVal={cashFlowCashActivity?.entryQueue}
                    updateValue={(v) => onChange('entryQueue', v)}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-left">Exit Queue ($)</td>
                <td className="text-right">
                  <FormInput
                    idx="valuation-exitQueue"
                    property="valuation-exitQueue"
                    displayName=''
                    type='float'
                    subtype='currency'
                    editMode={editMode}
                    propertyVal={cashFlowCashActivity?.exitQueue}
                    updateValue={(v) => onChange('exitQueue', v)}
                  />
                </td>
              </tr>
            </>)
          }
          { !editMode && (
            <>
            <tr className="row-highlight">
              <td className="text-left text-callan-blue">Uncalled Capital *</td>
              <td className="text-right background-blue-10">{ numbro(cashflowPerformance?.uncalledCapital || 0).format('$0,0.00') }</td>
            </tr>
            <tr className="row-highlight">
              <td className="text-left text-callan-blue" id="calledCapitalTooltipContainer">
                <div className="d-inline-flex align-items-center tooltip-icon" id="calledCapitalTooltip">
                  Called Capital *
                  <FontAwesomeIcon
                    icon={"question-circle" as IconName}
                    size="sm"
                  />
                </div>
              </td>
              <td className="text-right background-blue-10">{ numbro(cashflowPerformance?.calledCapital || 0).format('$0,0.00') }</td>
            </tr>
            </>
          )}
          <tr>
            <td className="text-left">Expected Called Capital (Next Quarter) ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-expectedCalledCapitalNextQuarter"
                property="valuation-expectedCalledCapitalNextQuarter"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.expectedCalledCapitalNextQuarter}
                updateValue={(v) => onChange('expectedCalledCapitalNextQuarter', v)}
              />
            </td>
          </tr>
          {!isPrivate && <tr>
            <td className="text-left">Expected Called Capital (Fund Life) ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-expectedCalledCapitalFundLife"
                property="valuation-expectedCalledCapitalFundLife"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.expectedCalledCapitalFundLife}
                updateValue={(v) => onChange('expectedCalledCapitalFundLife', v)}
              />
            </td>
          </tr>}
          { !editMode && (
            <tr className="row-highlight">
              <td className="text-left text-callan-blue">Distributed Capital *</td>
              <td className="text-right background-blue-10">{numbro(cashflowPerformance?.distributedCapital || 0).format('$0,0.00')}</td>
            </tr>
          )}
          <tr>
            <td className="text-left">Expected Distributed Capital (Next Quarter) ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-expectedDistributedCapitalNextQuarter"
                property="valuation-expectedDistributedCapitalNextQuarter"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.expectedDistributedCapitalNextQuarter}
                updateValue={(v) => onChange('expectedDistributedCapitalNextQuarter', v)}
              />
            </td>
          </tr>
          {!isPrivate && <tr>
            <td className="text-left">Expected Distributed Capital (Fund Life) ($)</td>
            <td className="text-right">
              <FormInput
                idx="valuation-expectedDistributedCapital"
                property="valuation-expectedDistributedCapital"
                displayName=''
                type='float'
                subtype='currency'
                editMode={editMode}
                propertyVal={cashFlowCashActivity?.expectedDistributedCapital}
                updateValue={(v) => onChange('expectedDistributedCapital', v)}
              />
            </td>
          </tr>}
        </tbody>
      </Table>
      <small className="text-gray-50">* Calculated using since inception transaction data.</small>
    </>
  )
}


export default ProductPerformanceVehiclesCashflow