import React from "react"
import { FirstDataRenderedEvent, GetDetailRowDataParams, GridReadyEvent, ICellRendererParams, ISetFilterParams, RowDataUpdatedEvent, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core"
import { capitalize, compact, isNull, uniq } from "lodash"
import { jobStatusCodeFilterRenderer, JobStatusComparator, JobStatusLogRenderer, jobStatusWithTooltipCellRenderer, textLink } from "../../helpers/agGridHelpers"
import moment from "moment"
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT, DATE_TIME_API_FORMAT } from "../../helpers/constant"
import { DateTimeComparator } from "../ControlCenter/Admin/DataImporter/ImportedFilesConfig"
import { Exact, JobStatusDetailsQuery, RunArg } from "../../__generated__/graphql"
import { LazyQueryExecFunction } from "@apollo/client"

export const PlanActivitiesColumnDef = (planId: number) => {
  return compact([{
    headerName: "Job Status ID",
    field: "id",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      innerRenderer: JobStatusLogRenderer,
    },
    sortable: true,
    width: 160,
  },
  {
    headerName: "Hidden(Test)",
    field: "hide",
    sortable: true,
    hide: true,
    width: 160,
  },
  {
    headerName: "Job Type",
    field: "type.value",
    sortable: true,
    width: 280,
  },
  {
    headerName: "Portfolio ID",
    field: "clientPortfolio.id",
    filterParams: {
      comparator: (a: string, b: string) => {
        const valA = parseInt(a);
        const valB = parseInt(b);
        if (valA === valB) return 0;
        return valA > valB ? 1 : -1;
      }
    },
    sortable: true,
    width: 130,
  },
  {
    headerName: "Portfolio Name",
    field: "clientPortfolio.name",
    cellRenderer: (params: ICellRendererParams) => textLink({text: params.value, url: "/clientportfolios/" + params.data?.clientPortfolio?.id, showHref: true}),
    sortable: true,
    width: 350,
  },
  {
    headerName: "Completed",
    field: "finishDate",
    valueGetter: (params: ValueGetterParams) => params.data.finishDate ? (moment(params.data.finishDate).format(DATE_TIME_API_FORMAT) as string || "") : "",
    valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format("MM/DD/YYYY HH:mm A") : "",
    comparator: (a: any, b: any) => DateTimeComparator(a, b, "top"),
    filter: "agDateColumnFilter",
    filterParams: {
      maxNumConditions: 1,
      debounceMs: 500,
      valueGetter: (params: ValueGetterParams) => params.data.finishDate ? moment(params.data.finishDate).format(DATE_API_FORMAT) : "",
      valueFormatter: (params:ValueFormatterParams)=> params.value ? moment(params.value).format(DATE_DISPLAY_FORMAT) : "",
      comparator: (a: any, b: any) => DateTimeComparator(a, b, "top"),
    },
    sortable: true,
    width: 165,
  },
  {
    headerName: "Job Status",
    field: "status",
    cellRenderer: jobStatusWithTooltipCellRenderer,
    comparator: JobStatusComparator,
    filterParams: {
      keyCreator: (params: any) => params.value.code,
      valueFormatter: (params: ValueFormatterParams) => params.value.name,
      cellRenderer: jobStatusCodeFilterRenderer,
    },
    sortable: true,
    width: 120,
  },
  {
    headerName: "Message",
    field: "standardMessage",
    valueGetter: (params: ValueGetterParams) => uniq(params.data.messages.map((message:any) => message?.standardMessage)) as string[],
    tooltipValueGetter: (params: ValueGetterParams) => uniq(params.data.messages.map((message:any) => message.standardMessage)) as string[],
    tooltipComponent: 'messageTooltip',
    tooltipComponentParams: { color: "white" },
    filterParams: {
      showTooltips: true,
      tooltipComponentParams: { color: "white" }
    } as ISetFilterParams,
    sortable: true,
    width: 280,
  },
  {
    headerName: "Details",
    field: "detailedMessage",
    valueGetter: (params: ValueGetterParams) => compact(uniq(params.data.messages.map((message:any) => capitalize(message?.detailedMessage || "")))) as string[],
    tooltipValueGetter: (params: ValueGetterParams) => compact(uniq(params.data.messages.map((message:any) => capitalize(message?.detailedMessage || "")))) as string[],
    tooltipComponent: 'messageTooltip',
    tooltipComponentParams: { color: "white" },
    filterParams: {
      showTooltips: true,
      tooltipComponentParams: { color: "white" }
    } as ISetFilterParams,
    sortable: true,
    width: 280,
  },
  ])
}

const jobDetailsRenderer = (params: ICellRendererParams) => {
  let data = params.data || []
  let {name, value} = data
  console.log(97, {params})
  return <span>{`${name}: ${value}`}</span>
  // return `${name}: ${value}`
  // return (
  //   <p key={params?.data?.id}>
  //     {data.map((arg: {name: string, value: string}, idx: number) => {
  //       let {name, value} = arg
  //       return (<React.Fragment key={idx}>{`${name}: ${value}`}<br/></React.Fragment>)
  //     })
  //     }
  //   </p>
  //  )
}

const timePeriodValueGetter = (params: ValueGetterParams) => {
  let args = params.data.args
  let endDate = args.find((el: RunArg) => el.friendlyName === "End Date")?.value
  let startDate = args.find((el: RunArg) => el.friendlyName === "Begin Date")?.value
  let isMonth = args.find((el: RunArg) => el.name === "MONTH")?.value === "Y"
  let duration = moment(endDate, "YYYYMM").diff(moment(startDate, "YYYYMM"), "months")
  let result = [
      {name: "Time Period", value: isMonth? "Months": "Quarters"}, 
      {name: "Months in Period", value: duration},
      {name: "Period End Date", value: endDate},
      {name: "Period Start Date", value: startDate}
  ]
  return result
}

const detailsArrayCellRenderer = (params: ICellRendererParams) => {
  return (
    <p style={{height: params.value.length * 20 + 20 + "px"}} className={'py-1'}>{params.value.map((arg: {name: string, value: string}, idx: number) => {
      if(!arg?.name) return <React.Fragment key={idx}/>
      let result = arg?.value
      if(isNull(result)) {
        result = "null"
      }
      return (
        <tr key={idx}>
          <td className={"pr-2"}>{arg?.name}:</td>
          <td>{result}</td>
        </tr>)
      })}
    </p>
  )
}

export const JobStatusMasterDetailsColumnDef = () => {
  return compact([
    {
      headerName: "Job Details",
      field: "args",
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.args.map((arg: RunArg) => {
          let {name, friendlyName, value} = arg
          return {name: friendlyName, value}
        })
      },
      cellRenderer: detailsArrayCellRenderer,
      sortable: true,
      flex:1,
      width: 920,
    },
    // {
    //   headerName: "Time Period",
    //   field: "timePeriod",
    //   valueGetter: timePeriodValueGetter,
    //   cellRenderer: detailsArrayCellRenderer,
    //   sortable: true,
    //   maxWidth: 500,
    //   flex:2,
    // },
  ])
}

export const PlanActivitiesDetailCellRendererParams = (queryFunction: LazyQueryExecFunction<JobStatusDetailsQuery, Exact<{id: any}>>) => ({
  detailGridOptions: {
    columnDefs: JobStatusMasterDetailsColumnDef(),
    enableCellTextSelection: true,
    onGridReady: (params: GridReadyEvent<any>) => {
      // params.api.setDomLayout("autoHeight");
    },
    onFirstDataRendered : (params: FirstDataRenderedEvent) => {
      params.api.onRowHeightChanged()
    },
    onRowDataUpdated: (params: RowDataUpdatedEvent) => {
      params.api.onRowHeightChanged()
    
    }
  },
  getDetailRowData: (params: GetDetailRowDataParams) => {
    setTimeout(() => {
      queryFunction({variables: {id: params.data.id}}).then(result => {
        let data = result.data?.jobStatus
        if(data?.args) {
          console.log("success", {params, data})
          params.successCallback([data])
        }else {
          console.log("fail", {params, data})
          params.successCallback([])
        }
      })
    }, 1000)
  },
})