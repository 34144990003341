import { CellValueChangedEvent, ColDef, ColumnApi, GridApi, GridReadyEvent, ProcessCellForExportParams } from "@ag-grid-community/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import iassign from "immutable-assign"
import { cloneDeep, compact, find, findIndex, some, sortBy, uniqueId } from "lodash"
import moment from "moment"
import numbro from "numbro"
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Col, Container, Row, Table, UncontrolledTooltip } from "reactstrap"
import { appDate } from "../../Context/CalendarContext"
import { EditButtonContext } from "../../Context/EditButtonContext"
import { AssetClassAbbreviationCode, BaseCurrencyCode, CashFlowMarketValueInput, CashFlowNetAssetValueInput, CashFlowTransactionTypeInput, CountryCode, DeleteCashFlowTransactionInput, PlanPerformanceCashFlowDocument, PlanPerformanceCashFlowFragment, PortfolioCurrencyCode, PortfolioDataType, TransactionTypeFragment, UniqueTransactionTypeCode, useDeleteClientPortfolioCashFlowMarketValueMutation, useDeleteSumSheetCashFlowMutation, useExchangeRatesQuery, usePlanPerformanceCashFlowQuery, useUpdatePlanPerformanceCashFlowMutation } from "../../__generated__/graphql"
import { PlanPortfolioCashFlowColumnDef } from "../../helpers/columnDefs"
import { DATE_API_FORMAT } from "../../helpers/constant"
import { currencySymbolMapping } from "../../helpers/helpers"
import { useDocumentTitle } from "../../helpers/usehook"
import { CalendarPickerPeriod, CalendarPickerType } from "../CalendarPicker"
import { currencyWithMantissaFormat } from "../Report/Shared/ReportComponent"
import { CashFlowDataType, TransactionTypeWithTitles } from "../Report/SumSheet"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import SortableTable from "../Shared/SortableTable"
import LoadingOverlay from "../ui/Loading"

export type ReturnsHandle = {
  handleSubmit: () => void;
};

export type CashFlowDataTypeWithEndingAssets =  Omit<CashFlowDataType, 'transactionType'> & {
  transactionType: UniqueTransactionTypeCode | "ending-assets"
  accruedInterests?: number | null
}

interface PlanPerformanceCashFlowProps {
  auth: any
  portfolioId: number
  editMode: boolean
  subtype?: string
  planId: number
  saving: boolean
  search: string
  selectedDataPeriod: CalendarPickerPeriod
  setSelectedDataPeriod: (period: CalendarPickerPeriod) => void
  selectedDate: string
  returnsRef: React.RefObject<ReturnsHandle>
  setSaving: (saving: boolean) => void
  setEditMode: (editMode: boolean) => void
  selectedType: CalendarPickerType
}

const usedHistoricalDate = moment(appDate).add(1, "quarter").endOf("month").format(DATE_API_FORMAT)

export const PlanPerformanceCashFlow: React.FC<PlanPerformanceCashFlowProps> = (props) => {
  const { auth, portfolioId, editMode, subtype, planId, saving, search, selectedDataPeriod, setSelectedDataPeriod, returnsRef, selectedDate, selectedType } = props
  const { setTitlePart } = useDocumentTitle()

  useEffect(() => {
    setTitlePart("Cash Flow", 1)
    return () => {
      setTitlePart("", 1)
    }
  }, [])

  const {data, error, loading} = usePlanPerformanceCashFlowQuery({
    variables: {
      id: portfolioId,
      date: selectedType === "historical" ? usedHistoricalDate : selectedDate,
      showMonth: selectedType !== "historical" && selectedDataPeriod === "month",
      showQuarter: selectedType !== "historical" && selectedDataPeriod === "quarter",
      showHistorical: selectedType === "historical",
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  })

  if(data && data.clientPortfolio && data.transactionTypeMap){
    return (
      <PlanPerformanceCashFlowDisplay
        auth={auth}
        portfolioId={portfolioId}
        editMode={editMode}
        planId={planId}
        saving={saving}
        data={data.clientPortfolio}
        transactionTypes={compact(data.transactionTypeMap)}
        search={search}
        selectedDataPeriod={selectedDataPeriod}
        setSelectedDataPeriod={setSelectedDataPeriod}
        ref={returnsRef}
        setSaving={props.setSaving}
        setEditMode={props.setEditMode}
        loading={loading}
        selectedDate={selectedDate}
        selectedType={selectedType}
      />
    )
  }
  return (
    <LoadingOverlay/>
  )
}

interface PlanPerformanceCashFlowDisplayProps {
  auth: any
  portfolioId: number
  editMode: boolean
  subtype?: string
  planId: number
  saving: boolean
  data: PlanPerformanceCashFlowFragment
  transactionTypes: TransactionTypeFragment[]
  search: string
  selectedDataPeriod: CalendarPickerPeriod
  setSelectedDataPeriod: (period: CalendarPickerPeriod) => void
  selectedDate: string
  ref: React.Ref<ReturnsHandle>
  setSaving: (saving: boolean) => void
  setEditMode: (editMode: boolean) => void
  loading: boolean
  selectedType: CalendarPickerType
}

// export type ReturnColumnDataType = PlanPerformanceFilteredCashFlowsFragment & {
//   rowId: string
//   previousId?: string
//   otherAssets?: number | null
//   touched?: boolean
// }

const netCashTypeMapping:{[key in UniqueTransactionTypeCode | "ending-assets"]: "+"|"-"|undefined} = {
  "C": "+",
  "CO": "+",
  "OR": "-",
  "TI": "+",
  "TR": "-",
  "EI": "+",
  "ER": "-",
  "D": "-",
  "CD": "+",
  "RC": "-",
  "DR": "-",
  "RD": "+",
  "DU": "-",
  "TO": "-",
  "TA": "+",
  "EO": "-",
  "EC": "+",
  "F": "-",
  "RF": "+",
  "FO": "-",
  "RO": "+",
  "FE": "-",
  "RE": "+",
  "CI": "-",
  "CL": "+",
  "I": undefined,
  "IA": undefined,
  "IL": undefined,
  "IP": undefined,
  "RA": undefined,
  "RI": undefined,
  "ending-assets": undefined,
}

const customExport = (columnDefs: ColDef[], lookupData: TransactionTypeWithTitles[]) => {
  let processCellCallback = (params:ProcessCellForExportParams) => {
    let colId = params.column.getColId()

    if(colId === "transactionType") {
      let matchedType = lookupData.find((item) => item.code === params.value)
      return matchedType?.name || params.value
    }
    return params.value
  }
  let columnKeys: string[] = columnDefs.filter(el => {
    if(!el?.field && !el?.colId) return false
    if(el?.field) return true
    return el.hide !== true
  }).map(el => el?.colId? el?.colId: el?.field as string)

  let result = {processCellCallback}
  return result
}

export const PlanPerformanceCashFlowDisplay: React.FC<PlanPerformanceCashFlowDisplayProps> = forwardRef((props, ref) => {
  const { auth, portfolioId, editMode, setEditMode, subtype, planId, saving, setSaving, data, transactionTypes, search, selectedDataPeriod, setSelectedDataPeriod, selectedDate, loading, selectedType} = props

  const [selectedRows, setSelectedRows] = useState<CashFlowDataTypeWithEndingAssets[]>([])
  const [initialLoad, setInitialLoad] = useState(true)
  const [valuesChanged, setValuesChanged] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null)
  const { setOptions } = useContext(EditButtonContext)
  // const { period: lockedPeriod } = useContext(CalendarContext)
  const [outstandingCalls, setOutstandingCalls] = useState(0)
  const history = useHistory()
  const [updatePlanPerformanceCashFlow] = useUpdatePlanPerformanceCashFlowMutation()
  const [deleteSumSheetCashFlow] = useDeleteSumSheetCashFlowMutation()
  const [deleteClientPortfolioCashFlowMarketValue] = useDeleteClientPortfolioCashFlowMarketValueMutation()

  let [firstDate, lastDate] = useMemo(() => {
    let firstDate = moment(selectedDate).subtract(1, selectedDataPeriod === "month" ? "months" : "quarters" ).endOf("month")
    let lastDate = moment(selectedDate).endOf("month")
    // //get first date from data
    if(selectedType === "historical"){
      data.historicalCashFlows?.cashFlowTransactionTypes?.forEach((type) => {
        type?.transactions?.forEach((transaction) => {
          if(transaction && moment(transaction.periodEndDate).isBefore(firstDate)){
            firstDate = moment(transaction.periodEndDate)
          } else if(transaction && moment(transaction.periodEndDate).isAfter(lastDate)){
            lastDate = moment(transaction.periodEndDate)
          }
        })
      })
    }
    return [firstDate, lastDate]
  }, [selectedDate, selectedDataPeriod, selectedType, data])

  const {data: exchangeRateData, error: exchangeRateError, loading: exchangeRateLoading } = useExchangeRatesQuery({
    variables: {
      filters: {
        startDate: firstDate.format(DATE_API_FORMAT),
        endDate: lastDate.format(DATE_API_FORMAT),
        currencyAbbr: data?.baseCurrency?.code || BaseCurrencyCode.USADOL,
      }
    },
    skip: !data?.baseCurrency?.code || data?.baseCurrency?.code === "USADOL",
  })

  useEffect(() => {
    setInitialLoad(true)
    if(columnApi){
      columnApi.resetColumnState()
      columnApi.applyColumnState({state: [
        {colId: 'date', sort:"desc"},
        {colId: 'type.value', sort:"asc"},
      ], applyOrder: true})
    }
    if(gridApi){
      gridApi.setFilterModel({
        'type.value': {
          filterType: 'set',
          values: ["Gross", "Net"]
        }
      })
    }
  }, [portfolioId])

  useEffect(() => {
    if(data.dataType?.code !== PortfolioDataType.SUM){
      if(editMode) setEditMode(false)
      setOptions({showButtons: false})
    } else {
      setOptions({showButtons: true})
    }
    return () => {
      setOptions({showButtons: true})
    }
  }, [data.dataType])

  useEffect(() => {
    if(gridApi) gridApi.refreshCells()
  }, [editMode])

  const endDate = moment(selectedDate)
  const startDate = moment(selectedDate).subtract(1, selectedDataPeriod === "month" ? "months" : "quarters").endOf("month")
  const startingExchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === startDate.format(DATE_API_FORMAT))?.rate
  const endingExchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === endDate.format(DATE_API_FORMAT))?.rate

  const [columnData, usedCashFlows, investmentGainLoss, convertedInvestmentGainLoss] = useMemo(() => {
    if(loading || !data){
      return [[], undefined, 0, 0]
    }
    let allTransactions:CashFlowDataTypeWithEndingAssets[] = []
    let usedCashFlows = selectedDataPeriod === "month" ? data?.monthCashFlows : data?.quarterCashFlows
    if(selectedType === "historical"){
      usedCashFlows = data?.historicalCashFlows
    }
    let investmentGainLoss = 0
    let convertedInvestmentGainLoss:number | undefined = 0
    usedCashFlows?.cashFlowTransactionTypes?.forEach((type) => {
      if(!type?.transactions) return
      allTransactions = allTransactions.concat(compact(type?.transactions?.map((transaction) => {
        if(!transaction || (selectedType !== "historical" && !moment(transaction.periodEndDate).isBetween(startDate, endDate, "D", "(]"))) return null
        let matchedExchangeRateData = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === transaction.adjustedTransactionDate)
        return ({
          rowId: `${type.transactionType.code}:${transaction.transactionDate}:${transaction.periodEndDate}:${transaction.transactionNumber}`,
          touched: false,
          transactionType: type.transactionType.code,
          convertedAmount: !!matchedExchangeRateData?.rate ? (transaction.amount || 0) * matchedExchangeRateData.rate : undefined,
          ...transaction,
        })
      })))
    })

    let {netCashActivity, convertedNetCashActivity} = allTransactions.reduce((acc, transaction) => {
      if(transaction.transactionType && netCashTypeMapping[transaction.transactionType]){
        acc.netCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? transaction.amount : -transaction.amount
        acc.convertedNetCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? (transaction.convertedAmount || 0) : -(transaction.convertedAmount || 0)
      }
      return acc
    }, {netCashActivity: 0, convertedNetCashActivity: 0})
    investmentGainLoss = (usedCashFlows?.endingNetAssetValue?.amount || 0) - (netCashActivity + (usedCashFlows?.beginningNetAssetValue?.amount || 0))
    const convertedEndingNetAssetValue = endingExchangeRate ? (usedCashFlows?.endingNetAssetValue?.amount || 0) * endingExchangeRate : undefined
    const convertedStartingNetAssetValue = startingExchangeRate ? (usedCashFlows?.beginningNetAssetValue?.amount || 0) * startingExchangeRate : undefined
    convertedInvestmentGainLoss = (convertedEndingNetAssetValue && convertedStartingNetAssetValue) ?  (convertedEndingNetAssetValue) - (convertedNetCashActivity + (convertedStartingNetAssetValue)) : undefined

    // add ending assets
    usedCashFlows?.marketValues?.forEach((marketValue) => {
      if(!marketValue || (selectedType !== "historical" && marketValue.date === startDate.format(DATE_API_FORMAT))) return
      let matchedExchangeRateData = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === marketValue.date)
      const { assetClassAbbreviation, __typename, amount, ...rest } = marketValue
      allTransactions.unshift({
        rowId: `ending-assets:${marketValue.date}`,
        touched: false,
        transactionType: "ending-assets",
        convertedAmount: !!matchedExchangeRateData?.rate ? (marketValue.amount || 0) * matchedExchangeRateData.rate : undefined,
        adjustedTransactionDate: marketValue.date,
        assetClassAbbreviation: { code: assetClassAbbreviation as AssetClassAbbreviationCode, __typename: "AssetClassAbbreviationLookup" },
        transactionDate: marketValue.date,
        periodEndDate: marketValue.date,
        transactionNumber: -1,
        amount: marketValue.amount + (marketValue.accruedInterests || 0),
        __typename: "CashFlowTransaction" as "CashFlowTransaction",
        ...rest,
      })
    })

    return [allTransactions, usedCashFlows, investmentGainLoss, convertedInvestmentGainLoss]
  }, [JSON.stringify(data), loading, exchangeRateData])
  const [currentColumnData, setCurrentColumnData] = useState<CashFlowDataTypeWithEndingAssets[]>(columnData)

  let {cashIn, convertedCashIn, cashOut, convertedCashOut, netCashWoFee, convertedNetCashWoFee} = columnData.reduce((acc, transaction) => {
    if(transaction.transactionType && netCashTypeMapping[transaction.transactionType]){
      if(netCashTypeMapping[transaction.transactionType] === "+"){
        acc.cashIn += transaction.amount
        acc.convertedCashIn += (transaction.convertedAmount || 0)
        if(!["RF","RO","CL"].includes(transaction.transactionType)){
          acc.netCashWoFee += transaction.amount
          acc.convertedNetCashWoFee += transaction.convertedAmount || 0
        }
      } else {
        acc.cashOut += transaction.amount
        acc.convertedCashOut += (transaction.convertedAmount || 0)
        if(!["F","FO","CI"].includes(transaction.transactionType)){
          acc.netCashWoFee -= transaction.amount
          acc.convertedNetCashWoFee -= transaction.convertedAmount || 0
        }
      }
    }
    return acc
  }, {cashIn: 0, convertedCashIn: 0, cashOut: 0, convertedCashOut: 0, netCashWoFee: 0, convertedNetCashWoFee: 0})

  let netCashActivity = cashIn - cashOut
  let convertedNetCashActivity = convertedCashIn - convertedCashOut
  let beginningAssets = usedCashFlows?.beginningNetAssetValue?.amount || 0
  let convertedBeginningAssets = startingExchangeRate ? beginningAssets * startingExchangeRate : undefined
  let endingAssets = netCashActivity + (usedCashFlows?.beginningNetAssetValue?.amount || 0) + investmentGainLoss
  let convertedEndingAssets = convertedBeginningAssets !== undefined && convertedInvestmentGainLoss !== undefined ? convertedNetCashActivity + (convertedBeginningAssets) + convertedInvestmentGainLoss : undefined
  let gainLoss = investmentGainLoss
  // let grossValue = gainLoss/(beginningAssets + (netCashActivity/2)) || 0
  // let convertedGrossValue = convertedInvestmentGainLoss/(convertedBeginningAssets + (convertedNetCashActivity/2)) || 0
  // let netValue = (endingAssets - beginningAssets - netCashWoFee)/(beginningAssets + (netCashWoFee/2)) || 0
  // let convertedNetValue = (convertedEndingAssets - convertedBeginningAssets - convertedNetCashWoFee)/(convertedBeginningAssets + (convertedNetCashWoFee/2)) || 0

  useEffect(() => {
    setCurrentColumnData(cloneDeep(columnData))
    if(columnApi) columnApi.applyColumnState({state: [
      {colId: 'transactionDate', sort:"desc"},
    ]})
  }, [columnData, editMode])

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    setColumnApi(params.columnApi)
    params.columnApi.applyColumnState({state: [
      {colId: 'transactionDate', sort:"desc"},
    ]})
  }

  useEffect(() => {
    if(outstandingCalls <= 0 && saving){
      setSaving(false)
      setEditMode(false)
    }
  }, [outstandingCalls])

  let largeTransactionWarning = some(currentColumnData, (item) => {
    if(usedCashFlows?.beginningNetAssetValue?.amount && item.amount > usedCashFlows?.beginningNetAssetValue?.amount/10){
      return true
    }
    return false
  })

  const onCellValueChanged = (params: CellValueChangedEvent) => {
    let updatedData = cloneDeep(currentColumnData)
    const row = find(updatedData, {rowId: params.data.rowId})
    if(row){
      row.touched = true
      let exchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === row.adjustedTransactionDate)?.rate || undefined
      row.convertedAmount = exchangeRate === undefined ? undefined : row.amount * exchangeRate
      setCurrentColumnData(updatedData)
    }
  }

  useImperativeHandle(ref, () => {
    return {
      handleSubmit() {
        setSaving(true)
        let upsertTransactions:CashFlowTransactionTypeInput[] = []
        let deleteTransactions:DeleteCashFlowTransactionInput[] = []
        let deleteMarketTransactions:CashFlowMarketValueInput[] = []
        let marketValues:CashFlowNetAssetValueInput[] = []
        currentColumnData.forEach((item) => {
          if(item.transactionType === "ending-assets"){
            if(item.touched){
              marketValues.push({
                date: item.transactionDate || item.adjustedTransactionDate,
                amount: item.amount,
                assetClassAbbreviation: item.assetClassAbbreviation?.code,
                country: item.country.code,
                currency: item.currency.code,
              })
            }
            return
          }
          const originalRow = find(columnData, {transactionDate: item.transactionDate, transactionNumber: item.transactionNumber})
          if(item.transactionNumber === -1 || !originalRow){
            // New Record
            const matchedIndex = findIndex(upsertTransactions, {transactionType: item.transactionType})
            let matchedType
            if(matchedIndex !== -1){
              matchedType = cloneDeep(upsertTransactions[matchedIndex])
            } else {
              matchedType = {
                transactionType: item.transactionType,
                transactions: [],
              }
            }
            matchedType.transactions?.push({
              transactionDate: item.transactionDate,
              assetClassAbbreviation: item.assetClassAbbreviation?.code,
              amount: item.amount,
              country: item.country?.code,
              currency: item.currency?.code,
              periodEndDate: item.periodEndDate,
            })
            if(matchedIndex !== -1){
              upsertTransactions[matchedIndex] = matchedType
            } else {
              upsertTransactions.push(matchedType)
            }
          } else if (originalRow && JSON.stringify(item) !== JSON.stringify(originalRow)){
            // Update Record
            const matchedIndex = findIndex(upsertTransactions, {transactionType: item.transactionType})
            let matchedType
            if(matchedIndex !== -1){
              matchedType = cloneDeep(upsertTransactions[matchedIndex])
            } else {
              matchedType = {
                transactionType: item.transactionType,
                transactions: [],
              }
            }
            matchedType.transactions?.push({
              transactionNumber: item.transactionNumber,
              transactionDate: item.transactionDate,
              assetClassAbbreviation: item.assetClassAbbreviation?.code,
              amount: item.amount,
              country: item.country?.code,
              currency: item.currency?.code,
              periodEndDate: item.periodEndDate,
            })
            if(matchedIndex !== -1){
              upsertTransactions[matchedIndex] = matchedType
            } else {
              upsertTransactions.push(matchedType)
            }
          }
        })
        columnData.forEach((item) => {
          if(item.transactionType === "ending-assets"){
            if(!find(currentColumnData, (currentItem) => { return item.transactionDate === currentItem.transactionDate && currentItem.transactionType === "ending-assets"})){
              // Deleted Record
              deleteMarketTransactions.push({
                date: item.transactionDate,
                assetClassAbbreviation: item.assetClassAbbreviation?.code,
                country: item.country.code,
                currency: item.currency.code,
              })
            }
          } else {
            if(!find(currentColumnData, (currentItem) => { return item.transactionDate === currentItem.transactionDate && item.periodEndDate === currentItem.periodEndDate && item.transactionNumber === currentItem.transactionNumber && currentItem.transactionType !== "ending-assets"})){
              // Deleted Record
              deleteTransactions.push({
                periodEndDate: item.periodEndDate,
                transactionDate: item.transactionDate,
                transactionNumber: item.transactionNumber,
              })
            }
          }
        })
        const updateClientPortfolio = upsertTransactions.length > 0|| marketValues.length > 0
        setOutstandingCalls((updateClientPortfolio ? 1 : 0) + (deleteTransactions.length > 0 ? 1 : 0) + (deleteMarketTransactions.length > 0 ? 1 : 0))
        if(updateClientPortfolio){
          updatePlanPerformanceCashFlow({
            variables: {
              input: {
                id: portfolioId,
                patch: {
                  cashFlows: {
                    cashFlowTransactionTypes: upsertTransactions,
                    marketValues: marketValues,
                  },
                }
              },
              date: selectedType === "historical" ? usedHistoricalDate : selectedDate,
              showMonth: selectedType !== "historical" && selectedDataPeriod === "month",
              showQuarter: selectedType !== "historical" && selectedDataPeriod === "quarter",
              showHistorical: selectedType === "historical",
            }
          }).then(result => {
            setOutstandingCalls((calls) => calls - 1)
          })
          .catch(err => {
            setOutstandingCalls((calls) => calls - 1)
            setSaving(false)
            console.error("Error updating sum sheet cash flow", err.message)
          })
        }
        if(deleteTransactions.length > 0){
          deleteSumSheetCashFlow({
            variables: {
              input: {
                id: portfolioId,
                transactions: deleteTransactions,
              },
            },
            update: (cache) => {
              const portfolio: any = cache.readQuery({
                query: PlanPerformanceCashFlowDocument,
                variables: {
                  id: portfolioId,
                  date: selectedType === "historical" ? usedHistoricalDate : selectedDate,
                  showMonth: selectedType !== "historical" && selectedDataPeriod === "month",
                  showQuarter: selectedType !== "historical" && selectedDataPeriod === "quarter",
                  showHistorical: selectedType === "historical",
                },
              })
              let usedKey = selectedDataPeriod === "month" ? "monthCashFlows" : "quarterCashFlows"
              if(selectedType === "historical") usedKey = "historicalCashFlows"
              let updatedPortfolio = iassign(
                portfolio,
                [`clientPortfolio`,usedKey,`cashFlowTransactionTypes`],
                (transactions) => {
                  let rows = cloneDeep(transactions as any[])
                  return rows.map((row) => {
                    let usedTransactions = row.transactions.filter((transaction:any) => !find(deleteTransactions, {transactionDate: transaction.transactionDate, transactionNumber: transaction.transactionNumber}))
                    return {
                      ...row,
                      transactions: usedTransactions,
                    }
                  })
                }
              )
              cache.writeQuery({
                query: PlanPerformanceCashFlowDocument,
                variables: {
                  id: portfolioId,
                  date: selectedType === "historical" ? usedHistoricalDate : selectedDate,
                  showMonth: selectedType !== "historical" && selectedDataPeriod === "month",
                  showQuarter: selectedType !== "historical" && selectedDataPeriod === "quarter",
                  showHistorical: selectedType === "historical",
                },
                data: updatedPortfolio,
              })
            }
          }).then(result => {
            setOutstandingCalls((calls) => calls - 1)
          })
          .catch(err => {
            setOutstandingCalls((calls) => calls - 1)
            setSaving(false)
            console.error("Error deleting sum sheet cash flow", err.message)
          })
        }
        if(deleteMarketTransactions.length > 0){
          deleteClientPortfolioCashFlowMarketValue({
            variables: {
              input: {
                id: portfolioId,
                marketValues: deleteMarketTransactions,
              },
            },
            update: (cache) => {
              const portfolio: any = cache.readQuery({
                query: PlanPerformanceCashFlowDocument,
                variables: {
                  id: portfolioId,
                  date: selectedType === "historical" ? usedHistoricalDate : selectedDate,
                  showMonth: selectedType !== "historical" && selectedDataPeriod === "month",
                  showQuarter: selectedType !== "historical" && selectedDataPeriod === "quarter",
                  showHistorical: selectedType === "historical",
                },
              })
              let usedKey = selectedDataPeriod === "month" ? "monthCashFlows" : "quarterCashFlows"
              if(selectedType === "historical") usedKey = "historicalCashFlows"
              let updatedPortfolio = iassign(
                portfolio,
                [`clientPortfolio`,usedKey,"marketValues"],
                (transactions) => {
                  let rows = cloneDeep(transactions as any[])
                  return rows.filter((transaction:any) => !find(deleteMarketTransactions, {date: transaction.date, assetClassAbbreviation: transaction.assetClassAbbreviation}))
                }
              )
              cache.writeQuery({
                query: PlanPerformanceCashFlowDocument,
                variables: {
                  id: portfolioId,
                  date: selectedType === "historical" ? usedHistoricalDate : selectedDate,
                  showMonth: selectedType !== "historical" && selectedDataPeriod === "month",
                  showQuarter: selectedType !== "historical" && selectedDataPeriod === "quarter",
                  showHistorical: selectedType === "historical",
                },
                data: updatedPortfolio,
              })
            }
          }).then(result => {
            setOutstandingCalls((calls) => calls - 1)
          })
          .catch(err => {
            setOutstandingCalls((calls) => calls - 1)
            setSaving(false)
            console.error("Error deleting sum sheet cash flow", err.message)
          })
        }
      }
    };
  }, [currentColumnData, columnData]);

  const addActive = editMode && !saving
  const addRow = () => {
    if(!addActive) return
    const rowId = uniqueId()
    let usedDate = selectedDate
    const latestEndingAssets = usedCashFlows?.marketValues?.reduce((a:any, b:any) => { return moment(a.date).valueOf() > moment(b.date).valueOf() ? a : b; }, [])
    if(selectedType === "historical"){
      usedDate = usedHistoricalDate
    }
    let newRow: CashFlowDataTypeWithEndingAssets = {
      rowId: `${rowId}`,
      adjustedTransactionDate: usedDate,
      transactionDate: usedDate,
      assetClassAbbreviation: {
        code: latestEndingAssets?.assetClassAbbreviation as AssetClassAbbreviationCode || data?.cashFlowDefaultKeys?.assetClassAbbreviation || AssetClassAbbreviationCode.ALL,
        __typename: "AssetClassAbbreviationLookup",
      },
      amount: 0,
      convertedAmount: 0,
      country: {
        code: data?.cashFlowDefaultKeys?.country || CountryCode.USA,
        __typename: "PortfolioCountryLookup",
      },
      currency: {
        code: data?.cashFlowDefaultKeys?.currency || PortfolioCurrencyCode.USADOL,
        __typename: "PortfolioCurrencyLookup",
      },
      periodEndDate: usedDate,
      touched: true,
      transactionNumber: -1,
      transactionType: "ending-assets",
      __typename: "CashFlowTransaction",
    }
    setCurrentColumnData([newRow, ...currentColumnData])
    if(gridApi){
      gridApi.paginationGoToPage(0);
      gridApi.ensureIndexVisible(0, "top")
    }
  }

  const deleteActive = editMode && !saving && selectedRows.length > 0
  const deleteRows = () => {
    if(!deleteActive) return
    setCurrentColumnData(currentColumnData.filter((item) => !selectedRows.includes(item)))
  }

  const usedSymbol = currencySymbolMapping[data?.baseCurrency?.code || BaseCurrencyCode.USADOL]
  const nonUsCurrency = data?.baseCurrency?.code !== BaseCurrencyCode.USADOL
  let transactionTitles = [
    {name: "Inflow", cashEffect: 1, fees: 0, title: true, code: "title-inflow"},
    {name: "Inflow of Fees", cashEffect: 1, fees: 1, title: true, code: "title-inflow-fees"},
    {name: "Outflow", cashEffect: -1, fees: 0, title: true, code: "title-outflow"},
    {name: "Outflow of Fees", cashEffect: -1, fees: 1, title: true, code: "title-outflow-fees"},
    {name: "Income", cashEffect: 0, fees: null, title: true, code: "title-income"}]
  const transactionTypeMapping = sortBy(transactionTypes, "order")?.flatMap((type) => {
    let foundTitleIdx = findIndex(transactionTitles, (title) => title.cashEffect === type?.cashEffect && (title.fees === type?.fees || title.fees === null))
    if(foundTitleIdx !== -1){
      const title = transactionTitles.splice(foundTitleIdx,1)[0]
      return [
        title,
        type
      ]
    }
    return type
  })
  const frequentlyUsed = [
    {name: "Frequently Used", cashEffect: 1, fees: 0, title: true, code: "title-frequently-used"},
    {name: "Called Capital", cashEffect: 1, fees: 0, code: "frequently-C"},
    {name: "End of Day Transfer In", cashEffect: 1, fees: 0, code: "frequently-EI"},
    {name: "Distributed Capital", cashEffect: -1, fees: 0, code: "frequently-D"},
    {name: "End of Day Transfer Out", cashEffect: -1, fees: 0, code: "frequently-EO"},
    {name: "Management Fee", cashEffect: -1, fees: 1, code: "frequently-F"},
    {name: "Return of Management Fee", cashEffect: 1, fees: 1, code: "frequently-RF"},
    {name: "Income Purchase", cashEffect: 0, fees: 0, code: "frequently-IP"},
    {name: "Income-Loss", cashEffect: 0, fees: 0, code: "frequently-IL"},
  ]

  let finalTypes = compact([{name: "Assets", title: true, code: "title-assets"}, {name: "Ending Assets", code: "ending-assets"},...frequentlyUsed ,...transactionTypeMapping])
  const colDef = PlanPortfolioCashFlowColumnDef(editMode, setCurrentColumnData, finalTypes, data?.baseCurrency?.code || BaseCurrencyCode.USADOL, startDate.add(1, "day").format(DATE_API_FORMAT), selectedType === "historical" ? usedHistoricalDate : endDate.format(DATE_API_FORMAT), (usedCashFlows?.beginningNetAssetValue?.amount || 0 )/10, selectedType === "historical")
  return (
    <Container fluid className="d-flex flex-grow-1 flex-direction-column px-0">
      <RouteLeavingGuard
        when={editMode && valuesChanged}
        navigate={path => history.push(path)}
      />
      <Row className="h-100">
        <Col sm={selectedType === "historical" ? 12 : 8} className="d-flex flex-grow-1 flex-direction-column">
          <div className="w-100 p-2 border-left border-right">
            {editMode &&
            <>
              <Button color={addActive ? "secondary" : "light"!} className="mr-auto" onClick={() => addRow()}>
                <span className={classNames({"text-gray-70": !addActive})}>
                  Add Row
                </span>
                <FontAwesomeIcon
                  icon="plus-circle"
                  className={classNames("ml-2", {"text-blue-100": addActive, "text-gray-70": !addActive})}
                />
              </Button>
              <Button color={deleteActive ? "secondary" : "light" } className="mr-auto ml-2" onClick={() => deleteRows()}>
                <span className={classNames({"text-gray-70": !deleteActive})}>
                  Delete Row
                </span>
                <FontAwesomeIcon
                  icon="trash"
                  className={classNames("ml-2", {"text-blue-100": deleteActive, "text-gray-70": !deleteActive})}
                />
              </Button>
            </>
            }
          </div>
          <SortableTable
            key={selectedDataPeriod}
            loading={false}
            filterText=""
            columnDefs={colDef}
            tableData={currentColumnData}
            rowId={"rowId"}
            editMode={editMode}
            setSelectedRows={setSelectedRows}
            onReady={onReady}
            onCellValueChanged={onCellValueChanged}
            exportParams={{allColumns: true, ...customExport(colDef, finalTypes)}}
            pageSize={100}
          />
        </Col>
        {selectedType !== "historical" &&
          <Col sm={4}>
            <Table className={classNames("side-assets-table", {"editing": editMode})}>
              <tr>
                <th colSpan={nonUsCurrency ? 3 : 2}>
                  Assets
                  {editMode &&
                    <>
                      <span id={`tooltip-cash-flow-asset`}>
                        <FontAwesomeIcon icon="info-circle" className="ml-1"/>
                      </span>
                      <UncontrolledTooltip placement="top" target={`tooltip-cash-flow-asset`}>
                        Save changes to update these totals.
                      </UncontrolledTooltip>
                    </>
                  }
                </th>
              </tr>
              <tr>
                <td className="text-left">Beginning Assets</td>
                <td className={classNames("text-right", {'negative-value': !!beginningAssets && beginningAssets < 0})}>{numbro(beginningAssets).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedBeginningAssets && convertedBeginningAssets < 0})}>{numbro(convertedBeginningAssets).format(currencyWithMantissaFormat)}</td>}
              </tr>
              <tr>
                <td className="text-left">Ending Assets</td>
                <td className={classNames("text-right", {'negative-value': !!endingAssets && endingAssets < 0})}>{numbro(endingAssets).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedEndingAssets && convertedEndingAssets < 0})}>{numbro(convertedEndingAssets).format(currencyWithMantissaFormat)}</td>}
              </tr>
              <tr>
                <td className="text-left">Gain/Loss</td>
                <td className={classNames("text-right", {'negative-value': !!gainLoss && gainLoss < 0})}>{numbro(gainLoss).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedInvestmentGainLoss && convertedInvestmentGainLoss < 0})}>{numbro(convertedInvestmentGainLoss).format(currencyWithMantissaFormat)}</td>}
              </tr>
              <tr>
                <th colSpan={nonUsCurrency ? 3 : 2}>
                  Cash Activity
                  {editMode &&
                    <>
                      <span id={`tooltip-cash-flow-cash-activity`}>
                        <FontAwesomeIcon icon="info-circle" className="ml-1"/>
                      </span>
                      <UncontrolledTooltip placement="top" target={`tooltip-cash-flow-cash-activity`}>
                        Save changes to update these totals.
                      </UncontrolledTooltip>
                    </>
                  }
                </th>
              </tr>
              <tr>
                <td className="text-left">Cash In</td>
                <td className={classNames("text-right", {'negative-value': !!cashIn && cashIn < 0})}>{numbro(cashIn).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedCashIn && convertedCashIn < 0})}>{numbro(convertedCashIn).format(currencyWithMantissaFormat)}</td>}
              </tr>
              <tr>
                <td className="text-left">Cash Out</td>
                <td className={classNames("text-right", {'negative-value': !!cashOut && cashOut < 0})}>{numbro(cashOut).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedCashOut && convertedCashOut < 0})}>{numbro(convertedCashOut).format(currencyWithMantissaFormat)}</td>}
              </tr>
              <tr>
                <td className="text-left">Net Cash Activity</td>
                <td className={classNames("text-right", {'negative-value': !!netCashActivity && netCashActivity < 0})}>{numbro(netCashActivity).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedNetCashActivity && convertedNetCashActivity < 0})}>{numbro(convertedNetCashActivity).format(currencyWithMantissaFormat)}</td>}
              </tr>
              {/* <tr>
                <th colSpan={nonUsCurrency ? 3 : 2}>
                  Returns
                  {editMode &&
                    <>
                      <span id={`tooltip-cash-flow-cash-returns`}>
                        <FontAwesomeIcon icon="info-circle" className="ml-1"/>
                      </span>
                      <UncontrolledTooltip placement="top" target={`tooltip-cash-flow-cash-returns`}>
                        Save changes to update these totals.
                      </UncontrolledTooltip>
                    </>
                  }
                </th>
              </tr>
              <tr>
                <td className="text-left">Gross</td>
                <td className={classNames("text-right", {'negative-value': !!grossValue && grossValue < 0})}>{numbro(grossValue).format(percentFormatSign)}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedGrossValue && convertedGrossValue < 0})}>{numbro(convertedGrossValue).format(percentFormatSign)}</td>}
              </tr>
              <tr>
                <td className="text-left">Net</td>
                <td className={classNames("text-right", {'negative-value': !!netValue && netValue < 0})}>{numbro(netValue).format(percentFormatSign)}</td>
                {nonUsCurrency && <td className={classNames("text-right", {'negative-value': !!convertedNetValue && convertedNetValue < 0})}>{numbro(convertedNetValue).format(percentFormatSign)}</td>}
              </tr> */}
            </Table>
          </Col>
        }
      </Row>
    </Container>
  )
})
